import { Injectable } from '@angular/core';
import { Resource } from '@app/core';
import { Producer } from '@app/core/producers-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProducerSelectionModalComponent } from './producer-selection-modal.component';

@Injectable()
export class ProducerSelectionModalFactory {

  constructor(private readonly modalService: NgbModal) { }

  open(options: ProducerSelectionModalOptions): Promise<Producer> {
    if (Object.isUndefined(options)) {
      throw new Error('options are required for producer selection modal');
    }

    const instance = this.modalService.open(ProducerSelectionModalComponent, {
      keyboard: false,
      backdrop: 'static'
    });
    instance.componentInstance.options = options;
    instance.componentInstance.ngOnChanges({ options: { currentValue: options } });
    return instance.result;
  }

  handleDismissal(error: any) {
    return ((typeof error !== 'object') || (error.dismissed !== true))
      ? Promise.reject(error)
      : undefined;
  }
}

export interface ProducerSelectionModalOptions {
  productId: string;
  selectedProducer: Producer;
  requireSelection?: boolean;
  title?: Resource;
  officeAssociationKey?: string;
}
