import { Directive, forwardRef, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, NgModel, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[mustNotContain]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MustNotContainValidator),
    multi: true
  }]
})
export class MustNotContainValidator implements Validator {
  @Input('mustNotContain') field: NgModel | string;

  validate(control: AbstractControl): ValidationErrors | null {
    if (Object.isUndefined(this.field) || Object.isUndefined(control.value)) {
      return null;
    }

    const fieldValue = this.field instanceof NgModel ? this.field.value : this.field;
    if (fieldValue && control.value.includes(fieldValue)) {
      return { mustNotContain: true };
    }

    return null;
  }
}