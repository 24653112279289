import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { InflatedQueryResult, Producer, ProducersService } from '@app/core/producers-api/index';
import { GeoService, ODataQueryBuilderService, Region } from '@core/index';
import { NgbActiveModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ProducerSelectionModalOptions } from './producer-selection-modal.factory';

@Component({
  selector: 'app-producer-selection-modal',
  templateUrl: './producer-selection-modal.component.html',
  styleUrls: ['./producer-selection-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProducerSelectionModalComponent implements OnInit, OnChanges {

  @Input() options: ProducerSelectionModalOptions;

  results: Producer[];
  regions: Region[];
  regionFilter: Region;
  searching: boolean;
  searchMsgVisible: boolean;
  searchQuery: string;

  readonly allRegions: Region = new Region('', '');

  private productId: string;
  private lastQuery: string;
  private lastRegion: Region;
  private selection: Producer;

  constructor(
    private readonly modalInstance: NgbActiveModal,
    private readonly geoService: GeoService,
    private readonly producersService: ProducersService,
    private readonly oDataQueryFactory: ODataQueryBuilderService) {
  }

  ngOnInit() {
    this.regionFilter = this.allRegions;
    this.lastRegion = this.allRegions;

    this.geoService.getRegionList().then(data => {
      const regions: Region[] = [];
      for (const k in data) {
        if (!data.hasOwnProperty(k)) { continue; }
        regions.push(data[k]);
      }
      this.regions = regions;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.isDefined(changes.options)) {
      const opts = changes.options.currentValue as ProducerSelectionModalOptions;
      this.selection = opts.selectedProducer;
      this.productId = opts.productId;
      this.getInitialResults();
    }
  }

  select(producer) {
    this.selection = producer;
    this.searchQuery = producer.name;
  }

  isSelected(producer) {
    return Object.isDefined(this.selection) && producer.id === this.selection.id;
  }

  get cancelIsDisabled() {
    // If we require a selection, we can only cancel if there was already a previous selection
    return this.options.requireSelection
      ? Object.isUndefined(this.options.selectedProducer)
      : false;
  }

  get selectIsDisabled() {
    return Object.isUndefined(this.selection);
  }

  accept() {
    this.modalInstance.close(this.selection);
  }

  cancel() {
    this.modalInstance.dismiss({ dismissed: true });
  }

  get hasNoResults() {
    return !this.searchMsgVisible && !this.searching && (Object.isUndefined(this.results) || this.results.length === 0);
  }

  predictionSelected(event: NgbTypeaheadSelectItemEvent) {
    this.searchQuery = event.item.name;
    this.selection = event.item;
    this.search();
  }

  search() {
    if ((String.equalsIgnoreCase(this.searchQuery, this.lastQuery)
      || (String.isUndefinedOrEmpty(this.searchQuery) && String.isUndefinedOrEmpty(this.lastQuery)))
      && (this.regionFilter === this.lastRegion)) {
      return;
    }

    this.lastQuery = this.searchQuery;
    this.lastRegion = this.regionFilter;
    this.results = [];
    this.searching = true;
    this.searchMsgVisible = false;

    const query = this.constructQuery(this.searchQuery);

    this.producersService.searchProducers(query)
      .then(result => {
        this.results = this.applyOfficeAssociationFilter(result);
        this.searching = false;
        this.searchQuery = this.lastQuery;
      })
      .catch(err => {

        this.searching = false;
        return Promise.reject(err);
      });
  }

  onSearchKeypress(evt) {
    if (evt.keyCode === 13) {
      this.search();
    }
  }

  private constructQuery(input: string, top?: number) {
    const builder = this.oDataQueryFactory.create();

    if (Object.isDefined(top)) {
      builder.top(top);
    }

    builder.filter.equal('productId', this.productId.toString())
      .and().equal('isActive', 'true')
      .and().beginGroup()
      .contains('officeName', input)
      .or().contains('producerName', input)
      .or().contains('phone', input)
      .or().contains('addressLineOne', input)
      .or().contains('locality', input)
      .or().contains('postalCode', input)
      .endGroup();

    if (Object.isDefined(this.regionFilter)) {
      builder.filter.and().equal('region', this.regionFilter.code);
    }

    return builder.build();
  }

  private getInitialResults() {
    const builder = this.oDataQueryFactory.create();
    const allQuery = builder.filter.equal('productId', this.productId.toString())
      .and().equal('isActive', 'true').query.build();
    const probeQuery = builder.top(1).build();

    this.producersService.searchProducers(probeQuery, false).then(probe => {

      if (probe.totalItemCount <= 20) {

        this.searching = true;
        this.producersService.searchProducers(allQuery)
          .then(results => {
            this.results = this.applyOfficeAssociationFilter(results);
            this.searching = false;
          })
          .catch(err => {
            this.searching = false;
            return Promise.reject(err);
          });
      } else {
        this.searchMsgVisible = true;

        if (Object.isDefined(this.selection)) {
          this.results = [this.selection];
        }
      }
    });
  }

  private applyOfficeAssociationFilter(results: InflatedQueryResult<Producer>) {
    if (this.options.officeAssociationKey) {
      return results.inflatedItems.filter(x => Object.isDefined(x.office?.associations[this.options.officeAssociationKey]));
    } else {
      return results.inflatedItems;
    }
  }

  getPredictions = (input: Observable<string>): Observable<Producer[]> => {
    return input.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(val => {
        if (String.isNotEmpty(val) && (val.length >= 3)) {
          const query = this.constructQuery(val, 10);
          return from(this.producersService.searchProducers(query))
            .pipe(
              map(result => {
                return this.applyOfficeAssociationFilter(result);
              }),
            );
        } else {
          return of([]);
        }
      })
    );
  };

  getPredictionDescription = (option: Producer) => {
    return option.name;
  };
}
