import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'password-requirements',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './password-requirements.component.html',
  styleUrl: './password-requirements.component.scss'
})
export class PasswordRequirementsComponent implements OnInit {

  @Input() password: string;
  @Input() confirmPassword: string;
  @Input() email?: string;
  @Input() firstName?: string;
  @Input() lastName?: string;

  private applicableValidations: (() => boolean)[];

  ngOnInit() {
    this.applicableValidations = [this.uniqueCharactersValidation, this.lengthValidation, this.confirmValidation];
    if (Object.isDefined(this.email)) {
      this.applicableValidations.push(this.noEmailValidation);
    }
    if (Object.isDefined(this.firstName)) {
      this.applicableValidations.push(this.noFirstNameValidation);
    }
    if (Object.isDefined(this.lastName)) {
      this.applicableValidations.push(this.noLastNameValidation);
    }
  }

  isValid() {
    let finalResult = true;
    for (const validation of this.applicableValidations) {
      const result = validation.call(this);
      finalResult &&= result;
    }
    return finalResult;
  }

  uniqueCharactersValidation() {
    const uniqueChars = new Set((this.password ?? '').toLowerCase());
    return uniqueChars.size >= 8;
  }

  noEmailValidation() {
    const password = this.password?.toLowerCase();
    const email = this.email?.toLowerCase();
    return password && email ? !password.includes(email) : false;
  }

  noFirstNameValidation() {
    const password = this.password?.toLowerCase();
    const firstName = this.firstName?.toLowerCase();
    return password && firstName ? !password.includes(firstName) : false;
  }

  noLastNameValidation() {
    const password = this.password?.toLowerCase();
    const lastName = this.lastName?.toLowerCase();
    return password && lastName ? !password.includes(lastName) : false;
  }

  lengthValidation() {
    return this.password?.length >= 12;
  }

  confirmValidation() {
    return this.password === this.confirmPassword && Boolean(this.password);
  }

  getValidationClass(validationFn: () => boolean): string {
    if (this.applicableValidations.includes(validationFn)) {
      return validationFn.call(this) ? 'validation-circle-valid' : 'validation-circle-invalid';
    }
    return '';
  }
}
