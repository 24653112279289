<div *ngIf="show()" class="alert alert-danger p-1 mt-1 mb-0">
  <ng-container *ngFor="let error of genericErrors">
    <div class="small" *ngIf="input.errors?.[error] && !hasSpecificError()">
      {{getErrorMessage(error)}}
    </div>
  </ng-container>
  <ng-container *ngFor="let error of specificErrors">
    <div class="small" *ngIf="input.errors?.[error]">
      {{ getErrorMessage(error) }}
    </div>
  </ng-container>
</div>