import { AfterContentInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Resource } from '@core/index';
import { AbstractValueAccessor, makeProvider } from '../../abstract-value-accessor';
import { Address } from '../../address';
import { AutocompleteInput, GooglePlacesApiFacadeService } from '../../google-places/index';
import { AddressFieldService } from '../address-field.service';
import { CompleteAddressValidator, CompleteAddressValidatorOptions } from '../complete-address.validator';

@Component({
  selector: 'address-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [makeProvider(() => AutocompleteComponent)],
  viewProviders: [{ provide: ControlContainer, deps: [NgForm], useExisting: NgForm }]
})
export class AutocompleteComponent extends AbstractValueAccessor implements AfterContentInit, OnDestroy {

  @Input() name: string;
  @Input() label: string | Resource;
  @Input() hideLabel: boolean;
  @Input() isRequired: boolean;
  @Input() isDisabled: boolean;
  @Input() shouldValidateStreet: boolean;
  @Input() completeAddress: CompleteAddressValidatorOptions;

  @ViewChild('placesInput', { read: ElementRef, static: true }) input: ElementRef;
  inputValue: string;
  private autocomplete: AutocompleteInput;
  private lastInputValue: string;

  constructor(private readonly placesFacade: GooglePlacesApiFacadeService, private readonly addressFieldService: AddressFieldService) {
    super();
    this.hideLabel = false;
    this.isRequired = false;
    this.isDisabled = false;
    this.shouldValidateStreet = false;
    this.label = 'address.address-autocomplete';
  }

  ngAfterContentInit() {
    this.autocomplete = this.placesFacade.createAutocomplete(this.input.nativeElement);
    this.autocomplete.onPlaceChanged.subscribe(place => {
      const addr = this.placesFacade.changePlaceToAddress(place);
      this.value = addr;
      this.lastInputValue = this.inputValue;

      if (!CompleteAddressValidator.areRequiredFieldsPresent(addr, this.completeAddress)) {
        this.addressFieldService.addressFieldWithAutocompleteError.next(this.name);
      }
    });
  }

  ngOnDestroy() {
    if (Object.isDefined(this.autocomplete)) {
      this.autocomplete.dispose();
    }
  }

  protected onValueSet(value: any) {
    this.inputValue = this.buildInputFieldValue(value);
    this.lastInputValue = this.inputValue;
  }

  handleInputChange() {
    /* This event seems to trigger at initial component load for IE which causes a situation where
     * the value gets nulled out without an actual change by a user. The input value check prevents this
     * from happening because if the values are the same it wasn't user input and thus we shouldn't
     * null out the value.
     *
     * Under normal conditions any change to the field invalidates the address since it wasn't picked
     * from the autocompleter.
     * */
    if (Object.isDefined(this.value) && (this.inputValue !== this.lastInputValue)) {
      this.value = undefined;
      this.lastInputValue = undefined;
    }
  }

  handleBlur() {
    this.fireOnTouched();
    // forces validators attached to this component to validate again
    // mainly done for the complete-address validator
    this.fireOnChange();
  }

  private buildInputFieldValue(address: Address) {
    if (Object.isDefined(address) && String.isNotEmpty(address.region)) {
      const values = [address.region, 'United States'];
      if (address.locality) {
        values.unshift(address.locality);
        if (address.addressLineOne) {
          values.unshift(address.addressLineOne);
        }
      }
      return values.join(', ');
    } else {
      return '';
    }
  }
}
