<fieldset class="form-section" [disabled]="isDisabled">
  <div class="row">
    <div class="col faux-material-form-group" validateInput>
      <label class="form-label" for="{{name}}-addressLineOne" [ngClass]="{'required':isRequired}">
        {{labels.addressLineOne | getresource}}
      </label>
      <input type="text" class="form-control" name="{{name}}-addressLineOne" id="{{name}}-addressLineOne"
             [(ngModel)]="address.addressLineOne" maxlength="250" (ngModelChange)="handleInputChange()"
             [required]="isRequired" [validateStreet]="options.requireValidStreet" #input="ngModel"/>
    </div>
  </div>

  <div class="row" *ngIf="options.includeUnitNumber">
    <div class="col faux-material-form-group" validateInput>
      <label class="form-label" for="{{name}}-unitNumber" [ngClass]="{'required':requireUnitNumber}">
        {{labels.unitNumber | getresource}}
      </label>
      <input type="text" class="form-control" maxlength="100" name="{{name}}-unitNumber" id="{{name}}-unitNumber"
             [(ngModel)]="address.unitNumber" (ngModelChange)="handleInputChange()" [required]="requireUnitNumber"  #input="ngModel"/>
    </div>
  </div>

  <div class="row" *ngIf="options.includeAddressLineTwo">
    <div class="col faux-material-form-group" validateInput>
      <label class="form-label" for="{{name}}-addressLineTwo">
        {{labels.addressLineTwo | getresource}}
      </label>
      <input type="text" class="form-control" maxlength="250" name="{{name}}-addressLineTwo"
             id="{{name}}-addressLineTwo" [(ngModel)]="address.addressLineTwo" (ngModelChange)="handleInputChange()"  #input="ngModel"/>
    </div>
  </div>

  <div class="row">
    <div class="col faux-material-form-group" validateInput="postalCodeErrorConfig">
      <label class="form-label" for="{{name}}-postalCode" [ngClass]="{'required':isRequired}">
        {{labels.postalCode | getresource}}
      </label>
      <input type="text" class="form-control" maxlength="20" name="{{name}}-postalCode" id="{{name}}-postalCode"
             pattern="\d{5}(-\d{4})?" [(ngModel)]="address.postalCode" (ngModelChange)="handleInputChange()"
             [required]="isRequired"  #input="ngModel"/>
    </div>
  </div>

  <div class="row">
    <div class="faux-material-form-group col-8" validateInput>
      <label class="form-label" for="{{name}}-locality" [ngClass]="{'required':isRequired}">
        {{labels.locality | getresource}}
      </label>
      <input type="text" class="form-control" maxlength="50" name="{{name}}-locality" id="{{name}}-locality"
             [(ngModel)]="address.locality" (ngModelChange)="handleInputChange()" [required]="isRequired"  #input="ngModel"/>
    </div>
    <div class="faux-material-form-group col-4" validateInput>
      <label class="form-label" for="{{name}}-region" [ngClass]="{'required':isRequired}">
        {{labels.region | getresource}}
      </label>
      <select class="form-select" maxlength="50" name="{{name}}-region" id="{{name}}-region"
              [(ngModel)]="address.region" (ngModelChange)="handleRegionChange()" [required]="isRequired"
              #region="ngModel" #input="ngModel">
        <option *ngFor="let region of regionList" [value]="region.code">{{region.code}}</option>
      </select>
    </div>
  </div>

  <div class="row" *ngIf="options.includeDistrict">
    <div class="col faux-material-form-group" validateInput>
      <label class="form-label" for="{{name}}-district" [ngClass]="{'required':requireDistrict}">
        {{getDistrictLabel() | getresource}}
      </label>
      <select class="form-select" maxlength="50" name="{{name}}-district" id="{{name}}-district"
              [(ngModel)]="address.district" (ngModelChange)="handleInputChange()" [required]="requireDistrict"
              #district="ngModel" #input="ngModel">
        <option *ngFor="let district of currentDistrictList" [value]="district">{{district}}</option>
      </select>
    </div>
  </div>
</fieldset>