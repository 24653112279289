import { Injectable } from '@angular/core';
import { HttpApiRequestService } from '@app/core/http-api-request.service';
import { ProducerSearchCriteria } from '@app/core/producers-api/producer-search-criteria';
import { ResourceLocation, ResourceQueryResult } from '@app/core/products-api';
import { environment } from '@env';
import { InflatedQueryResult } from './inflated-query-result';
import { Office, OfficeRetailerRelationship, Producer } from './producer';

@Injectable()
export class ProducersService {

  constructor(private readonly httpApiRequest: HttpApiRequestService) {
  }

  async assign(criteria: ProducerSearchCriteria): Promise<Producer> {
    if (Object.isUndefined(criteria) || String.isUndefinedOrEmpty(criteria.productId)) {
      return null;
    }

    const producer: Producer = await this.httpApiRequest.post(criteria, environment.producersApiUri, 'producer/assign');
    await this.updateOfficeInstance(producer);
    return producer;
  }

  reserve(criteria: ProducerSearchCriteria, producerId: number): Promise<void> {
    if (Object.isUndefined(criteria) || String.isUndefinedOrEmpty(criteria.productId)) {
      return null;
    }

    return this.httpApiRequest.post(criteria, environment.producersApiUri, 'producer/reserve', producerId);
  }

  searchProducers(odataQuery: string, inflate = true): Promise<InflatedQueryResult<Producer>> {
    odataQuery = String.prepend(odataQuery, '?');

    return this.httpApiRequest.get(environment.producersApiUri, 'producer', odataQuery)
      .then((result: ResourceQueryResult) => this.inflate<Producer>(result, inflate))
      .then((result: InflatedQueryResult<Producer>) => {
        // TODO: remove last condition once service versions are in sync
        if (!inflate || (result.totalItemCount === 0) || Object.isUndefined((<any>result.inflatedItems[0].office).url)) {
          return Promise.resolve(result);
        }

        const map: JsonObject = {};
        const requests: Promise<Office>[] = [];
        for (const item of result.inflatedItems) {
          if (!Object.isDefined(map[item.office.id])) {
            const url = (<ResourceLocation><any>item.office).url;
            map[item.office.id] = item.office; // placeholder
            requests.push(this.httpApiRequest.get(url).then(office => map[office.id] = office));
          }
        }

        return Promise.all(requests).then(() => {
          for (const item of result.inflatedItems) {
            item.office = map[item.office.id];
          }
          return result;
        });
      });
  }

  async getProducer(producerId: number): Promise<Producer> {
    if (producerId <= 0) { return Promise.resolve(null); }

    const producer = await this.httpApiRequest.get(environment.producersApiUri, 'producer', producerId);
    await this.updateOfficeInstance(producer);
    return producer;
  }

  async searchOffices(odataQuery: string, inflate = true): Promise<InflatedQueryResult<Office>> {
    odataQuery = String.prepend(odataQuery, '?');

    const result = await this.httpApiRequest.get(environment.producersApiUri, 'office', odataQuery);
    return this.inflate<Office>(result, inflate);
  }

  async getOfficesForPlatform(officeAssociationKey: string) {
    const result = await this.httpApiRequest.get(environment.producersApiUri, 'office');
    const officeMap = new Map<string, OfficeRetailerRelationship>();
    for (const office of result.items) {
      const targetOfficeId = office.associations[officeAssociationKey];
      if (targetOfficeId) {
        officeMap.set(office.name, { officeId: office.id, associationValue: targetOfficeId, associationKey: officeAssociationKey });
      }
    }
    return officeMap;
  }

  async getOfficeRetailerRelationshipForPlatform(officeAssociationKey: string): Promise<OfficeRetailerRelationship> {
    const result: OfficeRetailerRelationship[] = await this.httpApiRequest.get(environment.producersApiUri, 'office/relationship');
    let found: OfficeRetailerRelationship = result.find(x => x.associationKey == officeAssociationKey);
    return found;
  }

  relateOfficeAndRetailer(association: OfficeRetailerRelationship): Promise<void> {
    if (Object.isUndefined(association) || String.isUndefinedOrEmpty(association.associationKey)) {
      return null;
    }
    return this.httpApiRequest.put(association, environment.producersApiUri, 'office/relationship');
  }

  getOffice(officeId: number): Promise<Office> {
    if (officeId <= 0) { return Promise.resolve(null); }

    return this.httpApiRequest.get(environment.producersApiUri, 'office', officeId);
  }

  private async inflate<T>(result: ResourceQueryResult, shouldInflate: boolean): Promise<InflatedQueryResult<T>> {
    if ((result.totalItemCount === 0) || !shouldInflate) {
      return Promise.resolve({ ...result, inflatedItems: [] });
    }

    const promises = result.items.map(resource => this.httpApiRequest.get(resource.url));
    const inflatedItems = await Promise.all(promises);
    return { ...result, inflatedItems };
  }

  // the office sent by the service, depending upon version, is either a resource location or the full office
  private async updateOfficeInstance(producer: Producer): Promise<void> {
    if (Object.isUndefined(producer)) {
      return;
    }

    const office: any = producer.office;
    if (Object.isDefined(office.url)) {
      const reloadedOffice = await this.httpApiRequest.get((<ResourceLocation>office).url);
      producer.office = reloadedOffice;
    }
  }
}
