<ul class="list-unstyled text-start">
  <li>
    <div id="validation-twelvecharacters" class="validation-circle" [ngClass]="getValidationClass(lengthValidation)">
    </div>
    Must be at least 12 characters long
  </li>
  <li>
    <div id="validation-uniquecharacters" class="validation-circle"
         [ngClass]="getValidationClass(uniqueCharactersValidation)"></div>
    Minimum of 8 unique characters
  </li>
  <li>
    <div id="validation-noemail" class="validation-circle" [ngClass]="getValidationClass(noEmailValidation)"></div>
    <em>Can't contain your</em> email address
  </li>
  <li>
    <div id="validation-nofirstname" class="validation-circle" [ngClass]="getValidationClass(noFirstNameValidation)">
    </div>
    <em>Can't contain your</em> first name
  </li>
  <li>
    <div id="validation-nolastname" class="validation-circle" [ngClass]="getValidationClass(noLastNameValidation)">
    </div>
    <em>Can't contain your</em> last name
  </li>
  <li>
    <div id="validation-passwordsmatch" class="validation-circle" [ngClass]="getValidationClass(confirmValidation)">
    </div>
    Passwords must match
  </li>
</ul>