<div class="password-reset">
  <form *ngIf="displayForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <h4 class="text-center w-100 p-2">Reset your password</h4>
    <div>
      <label class="form-label">Email address</label>
      <input class="form-control revert" type="email" autocomplete="username" placeholder="example@rpsins.com"
             formControlName="email" autofocus />
      <form-errors [input]="resetForm.get('email')"></form-errors>
    </div>
    <div class="mb-1 mt-5 row">
      <button class="btn btn-action col mx-4" type="submit" [disabled]="formSubmitted">
        <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm" role="status"></span>
        Reset password
      </button>
    </div>
    <div class="text-center mt-3 w-100">
      <span>OR</span>
    </div>
    <div class="links text-center w-100">
      <a class="d-block p-3" [routerLink]="['/set-password']">I have a reset code</a>
    </div>
  </form>

  <div *ngIf="!displayForm && !isEmployee">
    <div class="alert alert-warning">
      <p class="fw-bold">Email sent</p>
      <p>In a moment you will receive a password reset link in your inbox. Click that link and follow the
        instructions to set your new password.</p>
    </div>
    <div class="mb-1 mt-5 row">
      <a class="btn btn-action col mx-4" [routerLink]="['/']">
        Return to welcome page
      </a>
    </div>
  </div>

  <div *ngIf="isEmployee">
    <div class="alert alert-danger">
      <p class="fw-bold">
        Cannot reset password
      </p>
      <p>
        As an RPS employee you must reset your password through Password Manager.
      </p>
      <div class="my-2 row">
        <a class="btn btn-action col mx-4" href="https://password.ajg.com/PMUser/">
          Go to Password Manager
        </a>
      </div>
      <p>
        Be aware: This will reset your password for the <em>entire network</em>
      </p>
    </div>
  </div>

  <div class="links text-center w-100">
    <a class="d-block p-3" [routerLink]="['/']">Show other login options</a>
  </div>
</div>