<div id="producer-selection-modal" class="producer-selection-modal">
  <div class="modal-header flex-column align-items-stretch text-center">
    <h4>{{'sidebar.select-producer-modal-header' | getgroupresource:'fulfillment'}}</h4>
    <h5 *ngIf="options.title">{{options.title | getresource}}</h5>
  </div>
  <div class="modal-body">
    <div class="row form-section">
      <div class="col-8 faux-material-form-group">
        <input type="text" id="producer-search" class="form-control"
               placeholder="{{'sidebar.search-placeholder' | getgroupresource:'fulfillment'}}" [(ngModel)]="searchQuery"
               autocomplete="off" [ngbTypeahead]="getPredictions" [inputFormatter]="getPredictionDescription"
               [resultTemplate]="optionTemplate" (selectItem)="predictionSelected($event)"
               (keydown)="onSearchKeypress($event)" (blur)="search()" [editable]="true" [showHint]="false"
               [focusFirst]="false" />
      </div>
      <div class="col-4 faux-material-form-group">
        <select name="producer-search-state-filter" class="form-select" [(ngModel)]="regionFilter"
                (ngModelChange)="search()">
          <option [ngValue]="allRegions">
            {{'sidebar.select-producer-modal.all-states'|getgroupresource:'fulfillment'}}
          </option>
          <option *ngFor="let region of regions" [ngValue]="region">{{region.name}}</option>
        </select>
      </div>
    </div>
    <div class="producer-selection-result-area">
      <img src="assets/images/spinner_squares_circle.gif" class="producer-selection-wait" *ngIf="searching" />
      <p class="producer-selection-results-msg" *ngIf="hasNoResults">
        {{'sidebar.no-search-results' | getgroupresource:'fulfillment'}}
      </p>
      <div class="producer-selection-results">
        <div class="row selectable-row" [ngClass]="{'selected-row': isSelected(p)}" *ngFor="let p of results"
             (click)="select(p)">
          <div class="col-5">
            <div class="fw-bold" name="producer-result-name">{{p.name}}</div>
            <div name="producer-result-office-name">{{p.office.name}}</div>
            <div name="producer-result-phone">{{p.phoneNumber | phoneNumber}}</div>
          </div>
          <div class="col-6 offset-xs-1">
            <div name="producer-result-address-line-one">{{p.office.mailingAddress.addressLineOne}}</div>
            <div name="producer-result-locality-region-postal">{{p.office.mailingAddress.locality}},
              {{p.office.mailingAddress.region}} {{p.office.mailingAddress.postalCode}}
            </div>
          </div>
        </div>
      </div>
      <p class="producer-selection-results-msg" *ngIf="searchMsgVisible">
        {{'sidebar.search-msg' | getgroupresource:'fulfillment'}}
      </p>
    </div>
  </div>
  <div class="modal-footer revert-btn d-flex align-items-end">
    <button id="select-producer-modal-cancel" class="btn btn-default" [disabled]="cancelIsDisabled"
            (click)="cancel()">{{'button.cancel' | getgroupresource:'fulfillment'}}</button>
    <button id="select-producer-modal-close" class="btn btn-action" [disabled]="selectIsDisabled"
            (click)="accept()">{{'sidebar.button.select-this-producer' | getgroupresource:'fulfillment'}}</button>
  </div>
</div>
<ng-template #optionTemplate let-match="result" let-term="term">
  <div class="container-fluid producer-selection-typeahead-item">
    <div class="row g-0">
      <div class="col-5">
        <div [innerHTML]="match.name | textHighlight:term"></div>
        <div [innerHTML]="match.office.name | textHighlight:term"></div>
        <div [innerHTML]="match.phoneNumber | textHighlight:term"></div>
      </div>
      <div class="col-6 offset-xs-1">
        <div [innerHTML]="match.office.mailingAddress.addressLineOne | textHighlight:term"></div>
        <div>
          <span [innerHTML]="match.office.mailingAddress.locality | textHighlight:term"></span>
          <span>,&nbsp;</span>
          <span [innerHTML]="match.office.mailingAddress.region | textHighlight:term"></span>
          <span>&nbsp;</span>
          <span [innerHTML]="match.office.mailingAddress.postalCode | textHighlight:term"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>