import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AddressFieldService {
  readonly addressFieldWithAutocompleteError: BehaviorSubject<string>;

  constructor() {
    this.addressFieldWithAutocompleteError = new BehaviorSubject(null);
  }
}
