import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Address } from '../address';

@Directive({
  selector: '[ngModel][completeAddress]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CompleteAddressValidator),
    multi: true
  }]
})
export class CompleteAddressValidator implements Validator {

  @Input('completeAddress') options?: boolean | CompleteAddressValidatorOptions;

  validate(control: AbstractControl) {
    const opts = this.getOpts();
    if (!opts.active) {
      return null;
    }

    const addr = control.value as Address;
    const result = (!control.touched && Object.isUndefined(addr)) || CompleteAddressValidator.areRequiredFieldsPresent(addr, opts);

    return result ? null : { incompleteAddress: true };
  }

  //This function just checks that all required fields are present,
  // this could (should?) be expanded to check for the validity of each field
  static areRequiredFieldsPresent(addr: Address, opts: CompleteAddressValidatorOptions) {
    const result =
      Object.isDefined(addr)
      && String.isNotEmpty(addr.addressLineOne)
      && String.isNotEmpty(addr.locality)
      && String.isNotEmpty(addr.region)
      && String.isNotEmpty(addr.postalCode)
      && (!opts.requireUnitNumber || String.isNotEmpty(addr.unitNumber))
      && (!opts.requireDistrict || String.isNotEmpty(addr.district));

    return result;
  }

  private getOpts(): CompleteAddressValidatorOptions {
    const opts = {
      active: true,
      requireDistrict: false,
      requireUnitNumber: false
    };

    if (Object.isDefined(this.options)) {
      if (typeof this.options === 'boolean') {
        opts.active = this.options;
      } else {
        opts.active = Object.isUndefined(this.options.active) || this.options.active;
        opts.requireDistrict = this.options.requireDistrict;
        opts.requireUnitNumber = this.options.requireUnitNumber;
      }
    }
    return opts;
  }
}

export interface CompleteAddressValidatorOptions {
  active: boolean;
  requireDistrict: boolean;
  requireUnitNumber: boolean;
}
