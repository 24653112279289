import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[uniqueCharacters]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => UniqueCharacterValidator),
    multi: true
  }]
})
export class UniqueCharacterValidator implements Validator {

  @Input() uniqueCharacters: number;

  validate(control: AbstractControl) {
    if (Object.isUndefined(control.value)) {
      return null;
    }

    const uniqueChars = new Set((control.value ?? '').toLowerCase());
    const valid = uniqueChars.size >= this.uniqueCharacters;
    return valid ? null : { uniqueCharacters: true };
  }
}
