import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Debug } from '@app/core';
import { ApiErrorOptions, ApiErrorService } from '@app/core/api-error.service';
import { AccountService, PasswordResetDetails } from '../account.service';

@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  formSubmitted = false;
  displayForm = true;
  isEmployee = false;
  resetLinkExpired = false;

  constructor(
    private form: FormBuilder,
    private route: ActivatedRoute,
    private readonly error: ApiErrorService,
    private api: AccountService
  ) { }

  ngOnInit() {
    this.createResetForm();
  }

  private createResetForm() {
    const email: string = this.route.snapshot.queryParamMap.get('email') || '';
    this.resetForm = this.form.group({
      email: [email, [Validators.required, Validators.email]]
    });
  }

  protected async onSubmit() {
    if (this.resetForm.valid) {
      const resetDetails: PasswordResetDetails = {
        email: this.resetForm.get('email').value
      };
      this.formSubmitted = true;

      try {
        const response = await this.api.resetPassword(resetDetails);
        Debug.debug('[Password Reset] Response:', response);
      } catch (e) {
        const error = e as HttpErrorResponse;

        // Internal User
        if (error.status === 405) {
          this.formSubmitted = false;
          this.isEmployee = true;
          this.displayForm = false;
          return;
        }

        const errorOptions: ApiErrorOptions = {
          message: error.error?.message,
          title: 'Reset Failed'
        };
        this.formSubmitted = false;
        this.error.processError(e, 'PasswordReset', errorOptions);
        return;
      }

      Debug.debug('[Password Reset] Password reset email sent');
      this.displayForm = false;
    }
  }
}
