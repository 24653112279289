<div class="table-footer hstack mb-3">
  <ngb-pagination *ngIf="total > pageSize" class="mb-2 mb-sm-0" [collectionSize]="total" [page]="page"
                  (pageChange)="setPage($event)" [pageSize]="pageSize" [maxSize]="maxSize"></ngb-pagination>
  <div class="mx-2 mb-md-0 pt-2 text-center">
    {{total | number}} {{(total === 1) ? plurals?.singular ?? 'result' : plurals?.plural ?? 'results' }} total
  </div>
  <select class="mb-2 mb-sm-0 form-select page-size" title="Set items per page" name="pageSize" [ngModel]="pageSize"
          (ngModelChange)="setPageSize($event)">
    <option [ngValue]="10">10 items per page</option>
    <option [ngValue]="25">25 items per page</option>
    <option [ngValue]="50">50 items per page</option>
    <option [ngValue]="100">100 items per page</option>
    <option [ngValue]="200">200 items per page</option>
    <option [ngValue]="500">500 items per page</option>
  </select>
  <button *ngIf="showScrollButton" type="button" class="mb-2 mb-sm-0 btn btn-primary" (click)="scrollToTop()">
    Back to top
  </button>
</div>