import { Component, Input } from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';
import { Debug } from '@app/core';

@Component({
  selector: 'form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {
  @Input() input!: NgModel | AbstractControl<any, any>;

  @Input() customMessageList: { [key: string]: string; } = {};

  // List of errors to check for, messages for these will block
  // messages for more general errors below
  public readonly specificErrors: string[] = [
    'email',
    'name',
    'businessName',
    'address',
    'addressLineTwo',
    'phone',
    'state',
    'zip',
    'checkboxRequired',
    'url',
    'phoneNumber',
    'mustMatch',
    'mustNotMatch',
    'forbiddenType',
    'invalidInput',
    'fileSize',
    'uniqueCharacters',
    'mustNotContain'
  ];

  // List of more generic errors, messages for these are only to be shown
  // if there are no specific errors
  public readonly genericErrors: string[] = [
    'required',
    'min',
    'minlength',
    'max',
    'maxlength',
    'isalphanumeric',
    'pattern'];

  private readonly defaultErrorMessages: Map<string, string> = new Map([
    ['required', `This field is required.`],
    ['isalphanumeric', `Must be alphanumeric (no special characters or accents)`],
    ['email', `Must be a valid email address.`],
    ['name', `Must contain valid name-style words (no special characters or accents)`],
    ['businessName', `Please limit name to letters, numbers, and these characters: ! @ # $ & * , . - : % (no accents)`],
    ['address', `Must contain a valid street address, including box number`],
    ['addressLineTwo', `Must contain a c/o, PO Box, or Apt/Suite number`],
    ['phone', `Phone number must be in the format of ### ### #### or # ### ### ####`],
    ['state', `State abbreviation must be two letters. (eg: CA or TX)`],
    ['zip', `Zip code must be in the format of ##### or #####-####`],
    ['checkboxRequired', `Please select at least one item.`],
    ['url', `Url is invalid`],
    ['phoneNumber', `Phone number must be in the format of ### ### #### or # ### ### ####`],
    ['mustMatch', `Values must be matched`],
    ['mustNotMatch', `Values must not matched`],
    ['forbiddenType', `Filetype not supported`],
    ['invalidInput', `This value is invalid`],
    ['fileSize', `File is too large`],
    ['uniqueCharacters', `Must contain more unique characters`],
    ['mustNotContain', `Contains forbidden string`]
  ]);

  constructor() { }

  humanReadableRegex(regex: string): string {
    const searchables = [
      { match: '0-9', description: 'numbers' },
      { match: ['a-z', 'A-Z'], description: 'letters' },
      { match: 'a-z', description: 'lowercase letters' },
      { match: 'A-Z', description: 'uppercase letters' },
      { match: '-', description: 'hyphens' },
      { match: '\'', description: 'apostrophes' },
      { match: ',', description: 'commas' },
      { match: '.', description: 'periods' },
      { match: ' ', description: 'spaces' },
      { match: ['(', ')'], description: 'parentheses' },
      { match: ['[', ']'], description: 'brackets' },
    ];
    regex = regex.slice(2, -3); // Remove the outside containers eg: [...]* or (...)+
    let message = '';
    for (let i = 0; i < searchables.length; i++) {
      const item = searchables[i];
      if (Array.isArray(item.match)) {
        if (regex.includes(item.match[0]) && regex.includes(item.match[1])) {
          regex = regex.replace(item.match[0], '');
          regex = regex.replace(item.match[1], '');
          message += `${item.description}, `;
        }
      } else if (regex.includes(item.match)) {
        regex = regex.replace(item.match, '');
        message += `${item.description}, `;
      }
    }
    message = message.slice(0, -2); // Remove the final comma and space
    if (regex.length) {
      message += ' as well as these characters: ' + regex;
    }
    return message;
  }

  protected show() {
    return this.input
      && this.input.errors
      && this.input.touched
      && this.input.invalid
      && [].concat(this.specificErrors, this.genericErrors).some(x => this.input.errors[x]);
  }

  // Returns true if there is a specific error
  protected hasSpecificError() {
    this.input.errors
    return (
      this.input.errors?.['email'] ||
      this.input.errors?.['name'] ||
      this.input.errors?.['businessName'] ||
      this.input.errors?.['address'] ||
      this.input.errors?.['addressLineTwo'] ||
      this.input.errors?.['phone'] ||
      this.input.errors?.['state'] ||
      this.input.errors?.['zip'] ||
      this.input.errors?.['checkboxRequired'] ||
      this.input.errors?.['url'] ||
      this.input.errors?.['phoneNumber'] ||
      this.input.errors?.['mustMatch'] ||
      this.input.errors?.['mustNotMatch'] ||
      this.input.errors?.['forbiddenType'] ||
      this.input.errors?.['invalidInput'] ||
      this.input.errors?.['fileSize'] ||
      this.input.errors?.['uniqueCharacters']
    );
  }

  getErrorMessage(key: string): string {
    if (!String.isUndefinedOrEmpty(this.customMessageList[key])) {
      return this.customMessageList[key];
    }

    if (key == 'minlength') {
      return `Value is too short. It must be at least ${this.input.errors?.['minlength'].requiredLength} characters long.`;
    }
    else if (key == 'min') {
      return `Value is too low. It must be at least ${this.input.errors?.['min'].min}.`;
    }
    else if (key == 'maxlength') {
      return `Value is too long. It must be at most ${this.input.errors?.['maxlength'].requiredLength} characters long.`;
    }
    else if (key == 'max') {
      return `Value is too high. It must be at most ${this.input.errors?.['max'].max}.`;
    }
    else if (key == 'pattern') {
      return `Value must contain only ${this.humanReadableRegex(this.input.errors?.['pattern'].requiredPattern)}.`;
    }
    else if (this.defaultErrorMessages.has(key)) {
      return this.defaultErrorMessages.get(key);
    }

    Debug.error('Specific error message missing.');
    return this.defaultErrorMessages.get('invalidInput');
  }
}
