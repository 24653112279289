import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpApiRequestService {

  constructor(private readonly http: HttpClient) { }

  /**
   * Sends an http GET request to a url.
   * @param url a url or an array of strings or numbers to concat into a url
   * @returns a promise
   */
  get(...url: (string | number)[]): Promise<any> {
    return this.send('GET', null, url);
  }

  /**
   * Sends an http POST request to a url.
   * @param data the data to post
   * @param url a url or an array of strings or numbers to concat into a url
   * @returns a promise
   */
  post(data, ...url: (string | number)[]): Promise<any> {
    return this.send('POST', data, url);
  }

  /**
   * Sends an http POST request to a url.
   * @param data the data to post
   * @param contentType The string to pass in the Content-Type header of the request
   * @param url a url or an array of strings or numbers to concat into a url
   * @returns a promise
   */
  postWithContentType(data, contentType: string, ...url: (string | number)[]): Promise<any> {
    return this.send('POST', data, url, false, null, contentType);
  }

  /**
   * Sends an http PUT request to a url.
   * @param data the data to put
   * @param url a url or an array of strings or numbers to concat into a url
   * @returns a promise
   */
  put(data, ...url: (string | number)[]): Promise<any> {
    return this.send('PUT', data, url);
  }

  /**
   * Sends an http DELETE request to a url.
   * @param url a url or an array of strings or numbers to concat into a url
   * @returns a promise
   */
  del(...url: (string | number)[]): Promise<any> {
    return this.send('DELETE', null, url);
  }

  /**
   * Creates and returns a rejected promise containing an error object
   * similar to what is returned by the http functions with a status of 0.
   * @param message - an error message string or an array of strings/error objects
   * @returns a rejected promise with the result of type {IApiError}
   */
  reject(message: string | (string | IErrorMessage)[]): Promise<any> {
    const result = {
      status: 0,
      messages: this.createErrorArray(message)
    };
    /* The promise interface is kind of funky in that T of IPromise<T> is the expected
     * return value IF the promise is successful. Otherwise, a rejected promise can return
     * any object. Typescript will expect the types to match which means for a rejection you
     * have to assert the promise is of type any or T.
     *
     * T can't be IApiError here otherwise we'll have assertions or other helper code to get
     * around the type issue so that's why any is used.
     */
    return Promise.reject(result);
  }

  /**
   * Sends an http request to a url.
   * @param method - the http method to use
   * @param data - the data to send or null/undefined
   * @param url - a url or an array of strings/numbers to combine into a url
   * @param keepHttpResponse - whether or not to return the body wrapped in angular's HttpResponse object
   * @param responseType - the expected response type (typically json which is the default)
   * @returns a promise
   */
  send(method: string, data: any, url: (string | (string | number)[]), keepHttpResponse = false, responseType?: string, contentType?: string): Promise<any> {

    let joinedUrl: string;

    if (Array.isArray(url)) {
      joinedUrl = this.joinUrl(url);
    } else if (typeof url === 'string') {
      joinedUrl = url;
    } else {
      throw new Error('expected "to" to be a url or an array of url parts');
    }

    const opts: JsonObject = {
      body: data,
      headers: { 'addAuthorization': 'true' },
      observe: keepHttpResponse ? 'response' : 'body'
    };

    if (String.isNotEmpty(responseType)) {
      opts.responseType = responseType;
    }

    if (String.isNotEmpty(contentType)) {
      opts.headers['Content-Type'] = contentType;
    }

    return this.http.request(method, joinedUrl, opts)
      .toPromise()
      .catch(this.unpackError.bind(this));
  }

  // used instead of array.join in order to remove the chance of double slashes if a part ends with a slash
  private joinUrl(parts: (number | string)[]): string {
    let url = '';
    for (const part of parts) {
      if (url.length && !String.endsWith(url, '/')) { url += '/'; }
      url += part;
    }
    return url;
  }

  private unpackError(error: HttpErrorResponse): Promise<IApiError> {
    const result = {
      status: error.status,
      messages: this.createErrorArray(error.error)
    };
    return Promise.reject(result);
  }

  private createErrorArray(data: string | (string | IErrorMessage)[]): IErrorMessage[] {
    if (typeof data === 'string') {
      return [{ code: 'message', message: data }];
    } else if (Array.isArray(data)) {
      return data.map(message => {
        return typeof message === 'string'
          ? { code: 'message', message: message }
          : message;
      });
    } else {
      return null;
    }
  }

}

export interface IApiError extends JsonObject {
  status: number;
  messages: IErrorMessage[];
}

export interface IErrorMessage {
  code: string;
  message: string;
}
